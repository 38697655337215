import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../theme";
import { ValidUserContext } from "../../login/authCheck";

const ContactDetails = ({
  contactDetails,
  onClose,
  refreshContacts,
  setIsEditable,
  isEditable,
  showSnackbar,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isDeleting, setIsDeleting] = useState(false);

  // Dialog states
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");





  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const requestBody = {
      fullName: values.fullName,
      commonName: values.commonName,
      phone: values.phone,
      email: values.email,
      facebook: values.facebook,
      instagram: values.instagram,
      websiteURL: values.websiteURL,
    };

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/contacts/update/${contactDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      refreshContacts();
      showSnackbar("Contacto editado com sucesso!");
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose();
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/contacts/delete/${contactDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the contact.");
         
        
      }

      refreshContacts();
      showSnackbar("Contacto removido com sucesso!");

      onClose();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao eliminar Contacto");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); 
    } finally {
      setIsDeleting(false);
      setOpenDeleteDialog(false); // Close delete confirmation dialog
    }
  };

  const canEditContacts =
    userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box m="20px">
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={{
          fullName: contactDetails.Full_Name || "",
          commonName: contactDetails.Common_Name || "",
          email: contactDetails.Email || "",
          phone: contactDetails.Phone_Number || "",
          facebook: contactDetails.Facebook || "",
          instagram: contactDetails.Instagram || "",
          websiteURL: contactDetails.Website_URL || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          submitForm,
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                gap="20px"
                flexDirection="column"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 6" },
                }}
              >
                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.fullName}
                  name="fullName"
                  error={!!touched.fullName && !!errors.fullName}
                  helperText={touched.fullName && errors.fullName}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={!!touched.email && !!errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Número de telemóvel"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  name="phone"
                  error={!!touched.phone && !!errors.phone}
                  helperText={touched.phone && errors.phone}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Facebook URL"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.facebook}
                  name="facebook"
                  error={!!touched.facebook && !!errors.facebook}
                  helperText={touched.facebook && errors.facebook}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Instagram URL"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.instagram}
                  name="instagram"
                  error={!!touched.instagram && !!errors.instagram}
                  helperText={touched.instagram && errors.instagram}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                <TextField
                  fullWidth
                  variant={isEditable ? "filled" : "standard"}
                  type="text"
                  label="Website URL"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.websiteURL}
                  name="websiteURL"
                  error={!!touched.websiteURL && !!errors.websiteURL}
                  helperText={touched.websiteURL && errors.websiteURL}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />
              </Box>
              {canEditContacts && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      onClick={() => setOpenDeleteDialog(true)}
                      color="error"
                      variant="contained"
                      disabled={isDeleting}
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Contacto"}
                    </Button>
                  )}

                  {isEditable && (
                    <Button
                      onClick={() => setOpenCancelDialog(true)}
                      color="error"
                    >
                      Cancelar Alterações
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      if (isEditable) {
                        setOpenSaveDialog(true); // Abre o diálogo de confirmação de salvamento
                      } else {
                        setIsEditable(true); // Habilita o modo de edição se não estiver editando
                      }
                    }}
                    sx={{
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? colors.customTheme.primary
                          : colors.customTheme.primary,
                      color: colors.customTheme.textColor,
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark" ? "#f0f0f0" : "#333",
                      },
                    }}
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isSubmitting
                      ? "A guardar..."
                      : isEditable
                      ? "Guardar Alterações"
                      : "Editar"}
                  </Button>
                </Box>
              )}
            </form>

            {/* Dialog for save confirmation */}
            <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    submitForm(); // Call submitForm from Formik
                    setOpenSaveDialog(false); // Fecha o diálogo após salvar
                  }}
                  color="primary"
                >
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for delete confirmation */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>Eliminar Contacto</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja eliminar este contacto? Esta ação não
                  pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={handleDelete} color="error">
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for cancel confirmation */}
            <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    resetForm(); // Reseta o formulário
                    setIsEditable(false);
                    setOpenCancelDialog(false);
                  }}
                  color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>



      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>


    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  fullName: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório"),
  phone: yup
    .string()
    .matches(phoneRegExp, "Número inválido")
    .required("Campo obrigatório"),
});

export default ContactDetails;
