import { useState, useEffect, useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { ValidUserContext } from "../../login/authCheck";
import Form from "../createUser";
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table";
import Overlay from "../../global/Overlay";
import UserDetails from "../userDetails";

const Users = () => {
  const theme = useTheme();
  const { userRoles } = useContext(ValidUserContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // Estado para o serviço selecionado
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [isEditable, setIsEditable] = useState(false); // Adicionando o estado de edição aqui

  const [overlayOpen, setOverlayOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Define a mapping from role codes to human-readable names
  const roleMapping = {
    SU: "Super User",
    ADMIN: "Administrador",
    NEWS: "Jornalista",
  };

  // Function to get the human-readable role name
  const getDisplayRoleName = (role) => roleMapping[role] || role;

  const fetchUsers = async () => {
    try {
      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/backoffice/users",
        {
          method: "GET",
          headers: {
            Accept: "application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch users");
      }

      const data = await response.json();
      const users = data.map((user, index) => ({
        id: index + 1,
        name: user.Name,
        email: user.Email,
        access: getDisplayRoleName(JSON.parse(user.Role)[0]), // Assuming Role is a JSON string
      }));
      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
      alert("Failed to fetch users. Please try again.");
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    { label: "ID", field: "id", hideAlways: true }, // ID hidden on mobile

    { label: "Nome", field: "name" },
    { label: "Email", field: "email", hideOnMobile: true },
    {
      label: "Permissões",
      field: "access",
      hideOnMobile: true,
      render: (rowData) => getDisplayRoleName(rowData.access),
    },
  ];

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpenUserDetails = (params) => {
    console.log(params);
    console.log(selectedUser);
    setSelectedUser(params);
    setIsCreatingUser(false); // Ensure we're not creating a new contact
    console.log(selectedUser);

    setOverlayOpen(true);
  };

  const handleCreateService = () => {
    setSelectedUser(null); // Limpa qualquer serviço selecionado
    setIsCreatingUser(true); // Modo de criação de serviço
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedUser(null);
    setIsCreatingUser(false); // Reseta o modo de criação de serviço
    setIsEditable(false);
  };

  const canAddUsers = userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box width="100%" overflow="auto">
      <Header
        title="Utilizadores"
        subtitle="Listagem de todos os utilizadores do backoffice"
        showPhotoImporter={false}
      />

      {/* Table with pagination */}
      <TableWithPagination
        columns={columns}
        rows={users}
        onRowClick={handleOpenUserDetails} // Open news details on row click
        onButtonClick={handleCreateService}
        canAddElements={canAddUsers}
        buttonTitle={"UTILIZADOR"}
      />
      <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingUser ? "Criar Utilizador" : "Detalhes do Utilizador"}
      >
        {selectedUser ? (
          <UserDetails
            userDetails={selectedUser}
            onClose={closeOverlay}
            refreshServices={fetchUsers}
            isEditable={isEditable} // Passa o estado atual para o formulário
            setIsEditable={setIsEditable}
            showSnackbar={showSnackbar} // Passa a função aqui
          />
        ) : (
          <Form
            onClose={closeOverlay}
            refreshNews={fetchUsers}
            showSnackbar={showSnackbar}
          />
        )}
      </Overlay>

      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default Users;
