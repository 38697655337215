import React, { useState, useEffect, useContext } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import TableWithPagination from "../../global/Table";
import { ValidUserContext } from "../../login/authCheck";
import { useTheme } from "@emotion/react";
import SnackbarComponent from "../../global/Snackbar";
import Overlay from "../../global/Overlay";
import AddCourseForm from "./addCourse";

const CoursesList = () => {
  const { userRoles } = useContext(ValidUserContext);
  const [courses, setCourses] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isCreatingCourse, setIsCreatingCourse] = useState(false);

  const [overlayOpen, setOverlayOpen] = useState(false);
  const navigate = useNavigate();
  const [frontendImage, setFrontendImage] = useState("");




  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");




  useEffect(() => {}, [isEditable]);

  const fetchCourses = async () => {
    const response = await fetch(
      "https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses"
    );
    const rawData = await response.json();
    const data = rawData.map((course) => ({
      ...course,
      id: course.ID,
    }));
    console.log(data);
    setCourses(data);
  };

  useEffect(() => {
    fetchCourses();
    fetchFrontendImage();
  }, []);

  const fetchFrontendImage = async () => {
    const response = await fetch(
      "https://faculdades-3cket-aefml.oa.r.appspot.com/api/menu-images/notes-bank"
    );
    const rawData = await response.json();

    setFrontendImage(rawData.ImageURL);
    console.log(rawData);
    console.log(rawData.ImageURL);
  };

  // Function to upload an image to the backend
  const onImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("name", "notes-bank");
    formData.append("photo", file);

    try {
      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/api/menu-images", // Adjust the endpoint as needed
        {
          method: "PUT",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const data = response.body;
        showSnackbar("Imagem adicionada com sucesso!");
        fetchFrontendImage();
      } else {
        setSnackbarMessage("Erro ao adicionar imagem. Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);         }
    } catch (error) {
      console.error("Error uploading image:", error);
      setSnackbarMessage("Erro ao adiconar fotografia. Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);         }
  };

  const columns = [
    { label: "ID", field: "id", hideAlways: true },
    {
      label: "Curso",
      field: "Name",
    },
    {
      label: "Fotografia",
      field: "ImageURL",
      isImage: true,
    },
  ];

  const handleOpenCourseDetails = (course) => {
    // Navigate to /bancoApontamentos/:courseId where :courseId is the course id
    navigate(`/bancoApontamentos/${course.id}`, { state: { course } });
  };

  const handleCreateCourse = () => {
    setIsCreatingCourse(true);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setIsCreatingCourse(false);
    setIsEditable(false);
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const canAddDocuments =
    userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" p={3}>
      {/* Page Header */}
      <Header
        title="Banco de Apontamentos"
        subtitle="Gere os cursos do banco!"
        currentImageUrl={frontendImage}
        onImageUpload={onImageUpload}
      />

      {/* Courses Table */}
      <TableWithPagination
        columns={columns}
        rows={courses}
        onRowClick={handleOpenCourseDetails} // Pass the row click handler
        buttonTitle={"Curso"}
        canAddElements={canAddDocuments}
        onButtonClick={handleCreateCourse}
      />

      <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title="Adicionar Curso"
      >
        <AddCourseForm
          onClose={closeOverlay}
          refreshCourses={fetchCourses}
          showSnackbar={showSnackbar}
        />
      </Overlay>

      <></>
      <SnackbarComponent
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default CoursesList;
