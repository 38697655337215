import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";

const PhotoManager = ({ sections }) => {
  const [sectionImages, setSectionImages] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingSection, setUploadingSection] = useState(null);
  const [previewImages, setPreviewImages] = useState({}); // For storing preview URLs
  const fileInputRefs = useRef({}); // Refs for file inputs per section

  // Fetch initial images for all sections
  useEffect(() => {
    const fetchImages = async () => {
      const updatedImages = {};
      for (const section of sections) {
        try {
          const response = await fetch(
            `https://faculdades-3cket-aefml.oa.r.appspot.com/api/menu-images/${section.id}`
          );
          const data = await response.json();
          updatedImages[section.id] = data.ImageURL;
        } catch (error) {
          console.error(`Failed to fetch image for ${section.title}:`, error);
        }
      }
      setSectionImages(updatedImages);
    };

    fetchImages();
  }, [sections]);

  // Handle image upload
  const handleImageUpload = async (file, sectionId) => {
    const formData = new FormData();
    formData.append("name", sectionId);
    formData.append("photo", file);

    setIsUploading(true);
    setUploadingSection(sectionId);

    try {
      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/api/menu-images",
        {
          method: "PUT",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSectionImages((prev) => ({
          ...prev,
          [sectionId]: data.ImageURL,
        }));
        window.location.reload(); // Refresh to clear cache
      } else {
        console.error("Failed to upload image for section:", sectionId);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
      setUploadingSection(null);
      setPreviewImages((prev) => ({ ...prev, [sectionId]: null })); // Clear preview
    }
  };

  // Handle file selection
  const handleFileSelect = (event, sectionId) => {
    const file = event.target.files[0];
    if (file) {
      // Preview image
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImages((prev) => ({
          ...prev,
          [sectionId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);

      // Store selected file
      if (!fileInputRefs.current[sectionId]) {
        fileInputRefs.current[sectionId] = {};
      }
      fileInputRefs.current[sectionId].file = file;
    }
  };

  // Confirm the upload
  const handleConfirm = (sectionId) => {
    const file = fileInputRefs.current[sectionId]?.file;
    if (file) {
      handleImageUpload(file, sectionId);
    }
  };

  // Cancel the operation
  const handleCancel = (sectionId) => {
    setPreviewImages((prev) => ({ ...prev, [sectionId]: null }));
    if (fileInputRefs.current[sectionId]) {
      fileInputRefs.current[sectionId].file = null;
    }
  };

  return (
    <Box flex={1} width="100%"  padding={3} boxShadow={3} borderRadius={2}>
      <Typography variant="h5" color="black" mb={3}>
        Fotos do Menu
      </Typography>

      <Box display="flex" flexWrap="wrap" gap="20px"             justifyContent="center"
      >
        {sections.map((section) => (
          <Box
            key={section.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap="10px"
            border="1px solid #000"
            borderRadius="8px"
            padding="20px"
          >
            <Typography variant="h6" color="black">
              {section.title}
            </Typography>

            {previewImages[section.id] ? (
              // Show preview image
              <>
                <img
                  src={previewImages[section.id]}
                  alt={`Preview for ${section.title}`}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "contain",
                    borderRadius: "8px",
                  }}
                />
                <Box display="flex" gap="10px" mt="10px">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleConfirm(section.id)}
                    disabled={isUploading}
                  >
                    Confirmar
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleCancel(section.id)}
                    disabled={isUploading}
                  >
                    Cancelar
                  </Button>
                </Box>
              </>
            ) : sectionImages[section.id] ? (
              // Show current image
              <img
                src={sectionImages[section.id]}
                alt={section.title}
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
            ) : (
              <Typography variant="body2" color="black">
                Nenhuma imagem disponível
              </Typography>
            )}

            {!previewImages[section.id] && (
              <Button
                variant="contained"
                component="label"
                sx={{
                  backgroundColor: "#000",
                  color: "#fff",
                  fontWeight: 400,
                }}
                disabled={isUploading && uploadingSection === section.id}
              >
                Alterar Foto
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  onChange={(event) => handleFileSelect(event, section.id)}
                />
              </Button>
            )}

            {isUploading && uploadingSection === section.id && (
              <CircularProgress size={24} sx={{ mt: 1 }} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default PhotoManager;
