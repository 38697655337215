import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Add useNavigate here
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Breadcrumbs,
  Link,
  Alert, Snackbar
} from "@mui/material";
import Header from "../../../components/Header";
import { ValidUserContext } from "../../login/authCheck";
import SnackbarComponent from "../../global/Snackbar";
import TableWithPagination from "../../global/Table"; // Custom table with pagination
import Overlay from "../../global/Overlay";
import AddFileForm from "./addDocument";
import FileDetails from "./documentDetails";
import useMediaQuery from "@mui/material/useMediaQuery";

const SubjectContent = () => {
  const { state } = useLocation();
  const { userRoles } = useContext(ValidUserContext);

  const course = state?.course;
  const subject = state?.subject;

  const [notes, setNotes] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isCreatingContent, setIsCreatingContent] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  const [overlayOpen, setOverlayOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // Dialog state
  const [selectedFileToDelete, setSelectedFileToDelete] = useState(null); // Track selected file to delete
  const isMobile = useMediaQuery("(max-width: 750px)");
  const navigate = useNavigate(); // Initialize useNavigate for breadcrumb navigation

  // Define columns for the content (files)
  const columns = [
    { label: "ID", field: "id", hideAlways: true },
    { label: "Nome do Ficheiro", field: "Name" },
    { label: "Data de Publicação", field: "Publication_Date" },
    { label: "Ficheiro", field: "FileURL" },
  ];

  const fetchSubjects = async () => {
    const response = await fetch(
      `https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses/${course.id}/subjects/${subject.id}/notes`
    );
    const rawData = await response.json();
    const data = rawData.map((course) => ({
      ...course,
      id: course.ID,
    }));
    console.log(data);
    setNotes(data);
  };

  useEffect(() => {
    if (course && subject) {
      fetchSubjects();
    }
  }, [course, subject]);

  // Função para enviar o formulário
  const handleFormSubmit = async (values, file, setSubmitting) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("file", file);

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses/${course.id}/subjects/${subject.id}/notes`; // Replace with your API

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Erro na rede ao tentar adicionar o ano");
      }
      const responseData = await response.text();
      fetchSubjects(); 
      showSnackbar("Ficheiro adicionado com sucesso!"); 
      closeOverlay(); 
    } catch (error) {
      console.error("Erro:", error);
      setSnackbarMessage("Erro ao adicionar ficheiro. Por favor tente novamente");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setSubmitting(false); // Reseta o estado de envio
  };

  // Method to handle course deletion
  const handleDeleteCourse = async () => {
    if (notes.length != 0) {
      setSnackbarMessage("Não pode eliminar esta cadeira porque tem ficheiros associados");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return
    }

    try {
      const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses/${course.id}/subjects/${subject.id}`; // Replace with your API
      const response = await fetch(url, { method: "DELETE" });

      if (!response.ok) {
   
        setSnackbarMessage("Erro ao eliminar do curso.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        throw new Error("Failed to delete course");
   
      }

      setSnackbarMessage("Curso eliminado com sucesso.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);  
      setOpenDeleteDialog(false); // Close the delete dialog
      setTimeout(() => {
        navigate(`/bancoApontamentos/${course.id}`, { state: { course } });
      }, 1000); 
        } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Ocorreu um erro ao eliminar a cadeira. Por  favor tente novamente");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);    }
  };





  // Method to handle course deletion
  const handleDeleteDocument = async (noteId) => {
    // Example of API call to delete the course
    try {
      const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses/${course.id}/subjects/${subject.id}/notes/${noteId}`; // Replace with your API
      const response = await fetch(url, { method: "DELETE" });

      if (!response.ok) {
        throw new Error("Failed to delete course");
      }

      showSnackbar("Ficheiro eliminado com sucesso!");
      setOpenDeleteDialog(false); // Close the delete dialog
      fetchSubjects();
      closeOverlay();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Ocorreu um erro ao eliminar ficheiro. Porfavor tente outra vez");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);    }
  };

  //  // Confirm deletion of the selected file
  //  const confirmDeleteFile = async () => {
  //   try {
  //     const url = `https://your-api-endpoint/files/delete/${selectedFileToDelete.id}`; // Replace with your API
  //     const response = await fetch(url, { method: "DELETE" });

  //     if (!response.ok) {
  //       throw new Error("Failed to delete file");
  //     }

  //     showSnackbar("Ficheiro eliminado com sucesso!");
  //     setOpenDeleteDialog(false); // Close the dialog
  //     fetchSubjects(); // Refresh content after deletion
  //   } catch (error) {
  //     console.error("Error:", error);
  //     showSnackbar("Ocorreu um erro ao eliminar o ficheiro.");
  //   }
  // };

  if (!course) {
    return <div>No course found.</div>;
  }

  if (!subject) {
    return <div>No subject found.</div>;
  }

  // Handle opening content details for editing
  const handleOpenContentDetails = (file) => {
    setSelectedContent(file);
    setIsCreatingContent(false);
    setOverlayOpen(true);
  };

  // Handle creating new content (file)
  const handleCreateContent = () => {
    setSelectedContent(null);
    setIsCreatingContent(true);
    setOverlayOpen(true);
  };

  // Handle deletion of a file (open the delete confirmation dialog)
  const handleDeleteFile = (file) => {
    setSelectedFileToDelete(file); // Set the selected file for deletion
    setOpenDeleteDialog(true); // Open the dialog
  };

  // Close overlay
  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedContent(null);
    setIsCreatingContent(false);
    setIsEditable(false);
  };

  // Show a snackbar message
  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Check if the user can add or delete content (files)
  const canAddContent = userRoles.includes("SU") || userRoles.includes("Admin");

  return (
    <Box width="100%" p={3}>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        {/* Link para Banco de Apontamentos */}
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate("/bancoApontamentos")} // Navegar para a página principal do banco de apontamentos
          style={{ cursor: "pointer" }}
        >
          Banco de Apontamentos
        </Link>

        {/* Link para o ano */}
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate(-1)} // Navegar de volta para a página do ano
          style={{ cursor: "pointer" }}
        >
          {course.Name} {/* Nome do ano */}
        </Link>

        {/* Nome da cadeira/assunto atual */}
        <Link underline="hover" color="text.primary" aria-current="page">
          {subject.name} {/* Nome do ficheiro/assunto atual */}
        </Link>
      </Breadcrumbs>

      <Header
        title={`${subject.name} (${course.Name})`}
        subtitle="Lista de ficheiros disponíveis"
        showPhotoImporter={false}
      />

      {/* Tabela de Conteúdos */}
      <TableWithPagination
        columns={columns}
        rows={notes}
        onRowClick={handleOpenContentDetails} // Open file details on row click
        onButtonClick={handleCreateContent} // Create new content
        onDeleteClick={handleDeleteFile} // Delete file
        canAddElements={canAddContent}
        canDeleteElements={canAddContent} // Allow deletion for admins
        buttonTitle="Ficheiro"
        deleteButtonTitle="Cadeira"
      />

      {/* Overlay for showing file details or creating new file */}
      <Overlay
        isOpen={overlayOpen}
        onClose={closeOverlay}
        title={isCreatingContent ? "Criar Ficheiro" : "Detalhes do Ficheiro"}
      >
        {selectedContent ? (
          <FileDetails
            fileDetails={selectedContent}
            onClose={closeOverlay}
            refreshContent={fetchSubjects}
            isEditable={isEditable}
            setIsEditable={setIsEditable}
            showSnackbar={showSnackbar}
            onDeleteDocument={handleDeleteDocument}
          />
        ) : (
          <AddFileForm
            onClose={closeOverlay}
            onAddDocument={handleFormSubmit}
            refreshContent={fetchSubjects}
            showSnackbar={showSnackbar}
          />
        )}
      </Overlay>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Eliminar Ficheiro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem a certeza que deseja eliminar o ficheiro{" "}
            {selectedFileToDelete?.name}? Esta ação não pode ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteCourse} color="error">
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
    
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SubjectContent;
