import React, { useState, useEffect } from "react";
import { Box, Switch, TextField, Typography } from "@mui/material";

const NotificationToggle = ({
  defaultTitle = "",
  defaultBody = "",
  screen,
  onSend,
}) => {
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const [title, setTitle] = useState(defaultTitle);
  const [body, setBody] = useState(defaultBody);

  // Handle the toggle switch change
  const handleToggleChange = () => {
    setIsNotificationEnabled((prev) => !prev);
  };

  useEffect(() => {
    // When notification is enabled, and we have valid data, send the notification
    if (isNotificationEnabled && onSend && title && body) {
      onSend({ title, body, screen });  // Call onSend (which is passed from parent) to send the notification
    }
  }, [isNotificationEnabled, title, body, screen, onSend]);

  return (
    <Box display="flex" flexDirection="column" gap="15px" sx={{ marginTop: "1.5rem" }}>
      <Box display="flex" alignItems="center" gap="10px">
        <Typography>Enviar Notificação?</Typography>
        <Switch
          checked={isNotificationEnabled}
          onChange={handleToggleChange}
          color="primary"
        />
      </Box>

      {isNotificationEnabled && (
        <Box display="flex" flexDirection="column" gap="15px">
          <TextField
            label="Título da Notificação"
            variant="outlined"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <TextField
            label="Corpo da Notificação"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Box>
      )}
    </Box>
  );
};

export default NotificationToggle;
