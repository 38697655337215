import React, { useState, useRef } from "react";
import { Typography, Box, useTheme, Button } from "@mui/material";
import { tokens } from "../theme";
import useMediaQuery from "@mui/material/useMediaQuery";

const Header = ({
  title,
  subtitle,
  showPhotoImporter = false,
  onImageUpload,
  currentImageUrl,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Refs to control the file input
  const fileInputRef = useRef(null);

  // States to manage the selected image and preview URL
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // New state to manage upload status

  // Detect if the screen is small (for mobile devices)
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  // Function to handle image selection
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);

      // Create a URL to display the image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Confirm and upload the selected image, then reset the preview
  const handleConfirm = async () => {
    if (selectedImage && onImageUpload) {
      setIsUploading(true); // Start loading
      try {
        await onImageUpload(selectedImage);
        console.log("Imagem alterada com sucesso.");
        window.location.reload(); // Refresh the page after successful upload
      } catch (error) {
        console.error("Failed to upload image:", error);
      } finally {
        setIsUploading(false); // End loading
      }
    }
  };


  // Function to cancel the operation
  const handleCancel = () => {
    resetImageSelection(); // Reset on cancel
  };

  // Reset image selection and clear preview
  const resetImageSelection = () => {
    setSelectedImage(null);
    setImagePreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input
    }
  };

  return (
    <Box
      mb="30px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={isSmallScreen ? "column" : "row"} // Change layout to column on small screens
    >
      {/* Title and subtitle */}
      <Box flex="1" textAlign="center" mb={isSmallScreen ? "20px" : "0"}>
        <Typography
          variant="h4"
          color="#000"
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
        >
          {title}
        </Typography>
        <Typography variant="h6" color="#000">
          {subtitle}
        </Typography>
      </Box>

      {/* Image importer, only appears if showPhotoImporter is true */}
      {showPhotoImporter && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={isSmallScreen ? "20px" : "0"}
        >
          <input
            accept="image/*"
            type="file"
            onChange={handleImageChange}
            style={{ display: "none" }}
            id="photo-importer"
            ref={fileInputRef} // Attach ref to the file input
          />

          {/* Button to open file input if no image is being previewed */}
          {!imagePreviewUrl && (
            <label htmlFor="photo-importer">
              <Button
                variant="contained"
                component="span"
                sx={{
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  fontWeight: 400,
                  marginBottom: "0.5rem",
                }}
              >
                Alterar Foto
              </Button>
            </label>
          )}

          {/* Show current image if not in edit mode */}
          {!imagePreviewUrl && currentImageUrl && (
            <img
              key={currentImageUrl}
              src={currentImageUrl}
              alt="Current"
              style={{
                width: "100px",
                height: "100px",
                objectFit: "contain",
                borderRadius: "8px",
              }}
            />
          )}

          {/* Show only the imported image preview */}
          {imagePreviewUrl && (
            <Box
              mt="10px"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <img
                key={imagePreviewUrl}
                src={imagePreviewUrl}
                alt="Preview"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                  borderRadius: "8px",
                }}
              />
              <Box mt="10px" display="flex" gap="10px">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                  disabled={isUploading} // Disable during upload
                >
                  {isUploading ? "Uploading..." : "Confirmar"}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleCancel}
                  disabled={isUploading} // Disable during upload
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Header;
