import React, { useState, useContext, memo } from "react";
import {
  Box, Button, TextField, CircularProgress, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert, Snackbar
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { MuiFileInput } from "mui-file-input";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { ValidUserContext } from "../../login/authCheck";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
const MemoizedTextField = memo(TextField);

const NewsDetails = ({ newsDetails, onClose, refreshNews, setIsEditable, isEditable, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deleting process

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);


// Dialog states
const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
const [openCancelDialog, setOpenCancelDialog] = useState(false);
const [openSaveDialog, setOpenSaveDialog] = useState(false);


const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState("");
const [snackbarSeverity, setSnackbarSeverity] = useState("success");





const handleChanges = (newFile) => {
  // Check if a file is selected
  if (newFile) {
      // Check if the file is an image
      if (!newFile.type.startsWith("image/")) {
          setSnackbarMessage("Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setFile(null); // Reset file state
          return; // Exit the function early
      }

      // Check the file size (limit to 20MB)
      const maxSizeInMB = 20; // Maximum size in MB
      if (newFile.size > maxSizeInMB * 1024 * 1024) {
          setSnackbarMessage("O tamanho do ficheiro não pode exceder 20MB.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setFile(null); // Reset file state
          return; // Exit the function early
      }
  }

  // If all checks pass, set the file
  setFile(newFile);
};



  // Form submission logic
  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    const formData = new FormData();

    Object.keys(values).forEach((key) => {
      if (key !== "photo") {
        formData.append(key, values[key]);
      }
    });

    if (file) {
      formData.append("photo", file);
    }

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/news/update/${newsDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");

      }
      refreshNews();
      showSnackbar("Notícia editada com sucesso!");

      const responseData = await response.text();
    } catch (error) {
      console.error("Error:", error);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose();

  };

  const handleDelete = async () => {
    setIsDeleting(true);

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/news/delete/${newsDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the news.");
      }

      refreshNews();
      showSnackbar("Notícia removida com sucesso!");

      onClose();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao eliminar a notícia. Por favor tente outra vez");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    finally {
      setIsDeleting(false);

    }
  };

  const initialValues = {
    title: newsDetails.Title || "",
    subtitle: newsDetails.Subtitle || "",
    author: newsDetails.Author || "",
    description: newsDetails.Description || "",
    photo: newsDetails.ImageURL || "",
    publicationDate: newsDetails.Publication_Date || "",
  };

  const canEditNews =
    userRoles.includes("SU") ||
    userRoles.includes("ADMIN") ||
    userRoles.includes("NEWS");

  return (
    <Box >
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          submitForm, 

        }) => (
          <>

          <form onSubmit={handleSubmit}>
            <Box
              display="flex"
              width="100%"
              flexDirection="column"
              gap="20px"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"

                label="Título"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                name="title"
                error={!!touched.title && !!errors.title}
                helperText={touched.title && errors.title}
                InputProps={{
                  readOnly: !isEditable,
                }}
              />

              {/* Image */}
              <img
                src={newsDetails.ImageURL}
                alt="Notícia"
                style={{
                  maxWidth: "200px",
                  
                  height: "auto",
                  borderRadius: "8px",
                }}
              />
              {isEditable && (
                <MuiFileInput
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachFileIcon />
                      </InputAdornment>
                    ),
                    style: { color: "#000" }, // Cor do texto de input para preto

                  }}
                  placeholder="Escolha uma imagem"
                  value={file}
                  onChange={handleChanges}

                />
              )}

              {/* Subtitle */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"
                label="Subtítulo"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subtitle}
                name="subtitle"
                InputProps={{
                  readOnly: !isEditable,
                }}
                error={!!touched.subtitle && !!errors.subtitle}
                helperText={touched.subtitle && errors.subtitle}
              />

              <MemoizedTextField
                fullWidth
                variant="filled"
                multiline
                minRows={6}
                maxRows={60}
                type="text"
                label="Corpo da Notícia"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                name="description"
                InputProps={{
                  readOnly: !isEditable,
                }}
                error={!!touched.description && !!errors.description}
                helperText={touched.description && errors.description}
              />

              {/* Author */}
              <MemoizedTextField
                fullWidth
                variant="filled"
                type="text"
                InputProps={{
                  readOnly: !isEditable,
                }}
                label="Autor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.author}
                name="author"
                error={!!touched.author && !!errors.author}
                helperText={touched.author && errors.author}
              />

              <TextField
                fullWidth
                variant="filled"
                type="text"

                label="Date de Publicação"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.publicationDate}
                name="publicationDate"
                error={!!touched.publicationDate && !!errors.publicationDate}
                helperText={touched.publicationDate && errors.publicationDate}
                InputProps={{
                  readOnly: !isEditable,
                }}
              />

              {canEditNews && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDeleteDialog(true)}
                      disabled={isDeleting} // Disable button while deleting
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Notícia"}
                    </Button>
                  )}

                  {isEditable && (
                    <Button
                    onClick={() => setOpenCancelDialog(true)}

                      color="error"
                    >
                      Cancelar Alterações
                    </Button>
                  )}

                  <Button
                     onClick={() => {
                      if (isEditable) {
                        setOpenSaveDialog(true); // Abre o diálogo de confirmação de salvamento
                      } else {
                        setIsEditable(true); // Habilita o modo de edição se não estiver editando
                      }
                    }}
                    sx={{
                      backgroundColor: theme.palette.mode === "dark" ? colors.customTheme.primary : colors.customTheme.primary,
                      color: colors.customTheme.textColor,
                      "&:hover": {
                        backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                      },
                    }}
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
                  >
                    {isSubmitting ? "A guardar..." : isEditable ? "Guardar Alterações" : "Editar"}
                  </Button>
                </Box>
              )}
            </Box>
          </form>

            {/* Dialog for save confirmation */}
            <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    submitForm(); // Call submitForm from Formik
                    setOpenSaveDialog(false); // Fecha o diálogo após salvar
                  }}
                  color="primary"
                >
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for delete confirmation */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>Eliminar Notícia</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja eliminar esta notícia? Esta ação não
                  pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={handleDelete} color="error">
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog for cancel confirmation */}
            <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    resetForm(); // Reseta o formulário
                    setIsEditable(false);
                    setOpenCancelDialog(false);
                  }}
                  color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Campo obrigatório"),
  subtitle: yup.string().required("Campo obrigatório"),
  author: yup.string().required("Campo obrigatório"),
  description: yup.string().required("Campo obrigatório"),
});

export default NewsDetails;