import React, { useState } from "react";
import { Box, Button, TextField, Alert, Snackbar } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { Formik } from "formik";
import * as yup from "yup";

import NotificationToggle from "../../global/ShowNotificationField";

const AddFileForm = ({ onAddDocument, showSnackbar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  const [notificationData, setNotificationData] = useState(null);


  const handleFileChange = (newFile) => {
    // Verifica se um arquivo foi selecionado
    if (newFile) {
      // Verifica se o arquivo é um PDF
      if (!newFile.type.includes("application/pdf")) {
        setSnackbarMessage("Apenas ficheiros PDF são permitidos.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Evita definir o estado do arquivo
      }

      // Verifica se o tamanho do arquivo excede 20MB
      if (newFile.size > 20 * 1024 * 1024) { // 20MB em bytes
        setSnackbarMessage("O tamanho máximo do ficheiro é de 20MB.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Evita definir o estado do arquivo
      }

      // Se a validação for bem-sucedida, define o estado do arquivo
      setFile(newFile);
    }
  };

  // Refactored handleFormSubmit function
  const handleFormSubmit = (values, { setSubmitting }) => {



    if (!file) {
      setSnackbarMessage("Por favor, adicione um ficheiro antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return

    }


    onAddDocument(values, file, setSubmitting);
  };




  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/api/notifications/send",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };


  return (
    <Box m="20px">
      <Formik
        initialValues={initialValues}
        validationSchema={fileSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap="20px">
              <TextField
                fullWidth
                variant="filled"
                label="Nome do Ficheiro"
                placeholder="Insira o nome do ficheiro"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                }}
                placeholder="Carregar Ficheiro"
                value={file}
                onChange={handleFileChange}
              />




                {/*
                   <NotificationToggle
                defaultTitle="Novos apontamentos adicionados"
                defaultBody={`Não percas!`}
                screen="/notes-bank"
              onSend={(data) => setNotificationData(data)}
              />

                
                */}

           

              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  fontWeight: 600,
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "A carregar ficheiro..." : "Criar Ficheiro"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#000',
            borderRadius: '4px',
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};


// Validation schema for form fields
const fileSchema = yup.object().shape({
  name: yup.string().required("O nome do ficheiro é obrigatório"),
});

const initialValues = {
  name: "",
};

export default AddFileForm;
