import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar, Alert
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ValidUserContext } from "../../login/authCheck";
import { tokens } from "../../../theme";
import { useTheme } from "@mui/material";

const Form = ({
  documentDetails,
  onClose,
  refreshDocuments,
  setIsEditable,
  isEditable,
  showSnackbar,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { userRoles } = useContext(ValidUserContext);
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false); 
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const initialValues = {
    title: documentDetails?.Title || "",
    file: documentDetails?.FileURL || "", 
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");





  const handleFormSubmit = async (values, { setSubmitting }) => {
    if (!isEditable) {
      setSubmitting(false);
      return;
    }

    if(!file){
      setSnackbarMessage("Por favor, adicione um ficheiro antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Prevent form submission
      return
  
     }

    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key !== "file") {
        formData.append(key, values[key]);
      }
    });

    if (file) {
      formData.append("file", file);
    }

    const url = documentDetails
      ? `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/update/${documentDetails.ID}`
      : `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/add`;

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.text();
      // console.log("Success:", responseData);

      refreshDocuments(); // Atualiza a lista após salvar alterações
      showSnackbar("Documento editado com sucesso!"); // Adicione esta linha
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao adicionar documento.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }

    setIsEditable(false);
    setSubmitting(false);
    onClose(); // Fecha o drawer após salvar
  };

  
  const handleDelete = async () => {
    setIsDeleting(true); // Set deleting state to true

    const url = `https://faculdades-3cket-aefml.oa.r.appspot.com/ae/delete/${documentDetails.ID}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to delete the document.");
      }

      // console.log("Document deleted successfully");
      refreshDocuments(); // Atualiza a lista após deletar o documento
      showSnackbar("Documento removido com sucesso!"); // Adicione esta linha

      onClose(); // Fecha o drawer após deletar
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao eliminar documento.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false); // Reset deleting state
    }
  };



  
  const handleFileChange = (newFile) => {
    // Verifica se um arquivo foi selecionado
    if (newFile) {
      // Verifica se o arquivo é um PDF
      if (!newFile.type.includes("application/pdf")) {
        setSnackbarMessage("Apenas ficheiros PDF são permitidos.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Impede a configuração do estado do arquivo
      }

      // Verifica se o tamanho do arquivo excede 20MB
      if (newFile.size > 20 * 1024 * 1024) { // 20MB em bytes
        setSnackbarMessage("O tamanho máximo do ficheiro é de 20MB.");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setFile(null);
        return; // Impede a configuração do estado do arquivo
      }

      // Se as validações forem bem-sucedidas, define o estado do arquivo
      setFile(newFile);
    }
  };
  const renderFileIcon = (fileUrl) => {
    if (!fileUrl) return null;

    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    let icon;

    switch (fileExtension) {
      case "pdf":
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
        break;
      default:
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
    }

    return (
      <IconButton
        component="a"
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
      </IconButton>
    );
  };

  const canEditDocuments =
    userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box m="20px">
      <Formik
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting, // Flag for spinner on save
          resetForm,
          submitForm, // <- Use submitForm provided by Formik
        }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                gap="20px"
                flexDirection="column"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Título"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  name="title"
                  error={!!touched.title && !!errors.title}
                  helperText={touched.title && errors.title}
                  InputProps={{
                    readOnly: !isEditable,
                  }}
                />

                {/* Render file icon */}
                {documentDetails && renderFileIcon(documentDetails.FileURL)}

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Data"
                  value={documentDetails?.Publication_Date || ""}
                  readOnly
                />

                {isEditable && (
                  <MuiFileInput
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {" "}
                          {/* Add position="start" */}
                          <AttachFileIcon />
                        </InputAdornment>
                      ),
                      style: { color: "#000" }, // Cor do texto de input para preto
                    }}
                    placeholder="Escolha um documento"
                    value={file}
                    onChange={handleFileChange}
                  />
                )}
              </Box>

              {/* Action buttons */}
              {canEditDocuments && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "red", // Keep the same color when disabled
                          opacity: 0.7, // Optionally add some opacity to indicate disabled state
                        },
                      }}
                      onClick={() => setOpenDeleteDialog(true)}
                      disabled={isDeleting} // Disable button while deleting
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Documento"}
                    </Button>
                  )}
                  {isEditable && (
                    <Button
                      onClick={() => setOpenCancelDialog(true)}
                      color="error"
                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "red", // Keep the same color when disabled
                          color: "#fff", // Ensure text stays white when disabled
                          opacity: 0.7, // Optionally add some opacity to indicate disabled state
                        },
                      }}
                    >
                      Cancelar Alterações
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      if (isEditable) {
                        setOpenSaveDialog(true); // Abre o diálogo de confirmação de salvamento
                      } else {
                        setIsEditable(true); // Habilita o modo de edição se não estiver editando
                      }
                    }}
                    sx={{
                      // mb: "1rem", // Add margin-bottom of 1rem
                      backgroundColor: colors.customTheme.primary,
                      color: "#fff", // Text color based on theme
                      "&:hover": {
                        backgroundColor: colors.customTheme.darkPrimary, // Hover state color
                      },
                      "&.Mui-disabled": {
                        backgroundColor: colors.customTheme.primary, // Keep the same color when disabled
                        color: "#fff", // Ensure text stays white when disabled
                        opacity: 0.7, // Optionally add some opacity to indicate disabled state
                      },
                    }}
                    variant="contained"
                    disabled={isSubmitting} // Desabilita o botão enquanto o formulário está sendo submetido
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isSubmitting
                      ? "A guardar..."
                      : isEditable
                      ? "Guardar Alterações"
                      : "Editar"}
                  </Button>
                </Box>
              )}
            </form>

            <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    submitForm(); // Call submitForm from Formik
                    setOpenSaveDialog(false); // Fecha o diálogo após salvar
                  }}
                  color="primary"
                >
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog>

{/* Dialog for delete confirmation */}
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>Eliminar Documento</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem a certeza que deseja eliminar este documento? Esta ação não
              pode ser desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDeleteDialog(false)}
              color="primary"
            >
              Cancelar
            </Button>
            <Button onClick={handleDelete} color="error">
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>

            {/* Dialog for cancel confirmation */}
            <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    resetForm(); // Reseta o formulário
                    setIsEditable(false);
                    setOpenCancelDialog(false);
                  }}
                  color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Formik>


        {/* Snackbar for messages */}
        <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "rgba(0, 0, 0, 0.7)", // Background color
            color: "#fff", // Text color
            borderRadius: "4px", // Rounded corners
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%", textAlign: "center" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  title: yup.string().required("required"),
});

export default Form;
