import { Box, IconButton, useTheme, useMediaQuery, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import PersonOutline from "@mui/icons-material/PersonOutline";
import { ValidUserContext } from "../login/authCheck";
import { useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import logo from '../../assets/logoAE.png';

import { NotificationAddOutlined, Person2Outlined,  LibraryBooksOutlined } from "@mui/icons-material";

// Item Component for Sidebar Items
const Item = ({ title, to, icon, selected, setSelected, setDrawerOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClick = () => {
    setSelected(title); // Atualiza o item selecionado
    if (setDrawerOpen) {
      setDrawerOpen(false); // Fecha o Drawer
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: selected === title ? colors.customTheme.darkPrimary : 'transparent',
        borderRadius: '8px',
        "&:hover": {
          backgroundColor: colors.customTheme.darkPrimary,
        },
        margin: '10px 0',
        listStyle: 'none',
        "& .pro-inner-item": {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '10px',
          padding: '10px 20px',
        },
        "& .pro-icon-wrapper": {
          display: 'flex',
          alignItems: 'center',
        },
        "& .pro-item-content": {
          display: 'flex',
          alignItems: 'center',
        },
      }}
    >
      <MenuItem
        active={selected === title}
        style={{
          color: colors.customTheme.textColor,
          fontWeight: 'bolder',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        }}
        onClick={handleClick}
        icon={<Box sx={{ color: colors.customTheme.textColor }}>{icon}</Box>}
      >
        <Link to={to} style={{ textDecoration: 'none', color: 'inherit', width: '100%' }}>
          <Typography>{title}</Typography>
        </Link>
      </MenuItem>
    </Box>
  );
};

const SidebarMobile = ({ selected, setSelected, drawerOpen, setDrawerOpen }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();

  useEffect(() => {
    const pathToTitleMap = {
      "/users": "Utilizadores",
      "/ae": "Documentos",
      "/news": "Notícias",
      "/services": "Serviços",
      "/contacts": "Contactos",
      "/bancoApontamentos": "Sebenta",
      "/notifications": "Notificações",
    };

    const currentPath = location.pathname;
    const matchedTitle = Object.keys(pathToTitleMap).find((path) =>
      currentPath.startsWith(path)
    );
    if (matchedTitle) {
      setSelected(pathToTitleMap[matchedTitle]);
    }
  }, [location.pathname, setSelected]);

  return (
    <>
      <IconButton
        onClick={() => setDrawerOpen(true)} // Abre o drawer
        sx={{
          left: '10px',
          zIndex: 0,
          color: theme.palette.primary.main, // Define a mesma cor que os outros ícones
          display: drawerOpen ? "none" : "block",
        }}
      >
        <MenuIcon />
      </IconButton>

      {drawerOpen && (
        <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",  // Full-width sidebar
          backgroundColor: colors.customTheme.primary,
          zIndex: 4,
          
          "& .pro-sidebar": {
            background: colors.customTheme.primary,
            width:'100%'
          },
          "& .pro-sidebar-inner": {
            background: colors.customTheme.primary,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
          },
          "& .pro-inner-item:hover": {
            color: theme.palette.mode === "dark" ? "#fff" : "",
          },
          "& .pro-menu-item.active": {
            color: colors.customTheme.darkPrimary,
          },
      
      }}
        >
          <IconButton
            onClick={() => setDrawerOpen(false)}
            sx={{
              alignSelf: "flex-end",
              margin: "1rem",
              color: colors.customTheme.textColor,
              position: 'absolute',
              right: 20,
              top: 20,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '1rem',
            }}
          >
            <img
              src={logo}
              alt="3cketBeyond"
              style={{ width: "50%", height: "auto", margin: "3rem 0rem" }}
            />
          </Box>

          <ProSidebar
            sx={{
              height: "100%", 
              background: colors.customTheme.primary,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Menu
              sx={{
                backgroundColor: colors.customTheme.primary,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: "0 10%", 
                  width: "100%", 
                  backgroundColor: colors.customTheme.primary,
                }}
              >
                <Item
                  title="Utilizadores"
                  to="/users"
                  icon={<Person2Outlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Documentos"
                  to="/ae"
                  icon={<DocumentScannerOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Notícias"
                  to="/news"
                  icon={<NewspaperOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Serviços"
                  to="/services"
                  icon={<RoomServiceOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Contactos"
                  to="/contacts"
                  icon={<ContactsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Sebenta"
                  to="/bancoApontamentos"
                  icon={<LibraryBooksOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
                <Item
                  title="Configurações"
                  to="/notifications"
                  icon={<NotificationAddOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                  setDrawerOpen={setDrawerOpen}
                />
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      )}
    </>
  );
};

// Topbar Component
const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const validUserContext = useContext(ValidUserContext);
  const colors = tokens(theme.palette.mode);
  const [drawerOpen, setDrawerOpen] = useState(false); // Gerencie o estado do drawer
  const isSmallScreen = useMediaQuery('(max-width:750px)'); // Verifica se está em uma tela pequena
  const [selected, setSelected] = useState("Notícias"); // Estado para item selecionado

  const handleLogout = () => {
    validUserContext.logout();
  };

  const navigateToProfile = () => {
    navigate("/profile");
  };

  return (
    <Box position="absolute" width="100%" display="flex" top="0" justifyContent="space-between" p={2}>
      
      {/* Menu Icon and SidebarMobile only visible on small screens */}
      {isSmallScreen && (
        <SidebarMobile
          selected={selected}
          setSelected={setSelected}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}
  
      <Box />
  
      <Box display="flex" alignItems="center">
        <Box display="flex" ml={2}>
          <IconButton onClick={navigateToProfile}>
            <PersonOutline sx={{ color: theme.palette.primary.main }} />
          </IconButton>
  
          <IconButton onClick={handleLogout}>
            <LogoutOutlined sx={{ color: theme.palette.primary.main }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
