import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Snackbar
} from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ValidUserContext } from "../../login/authCheck";

const FileDetails = ({
  fileDetails,
  onClose,
  refreshContent,
  isEditable,
  showSnackbar,
  onDeleteDocument,
}) => {
  const { userRoles } = useContext(ValidUserContext);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [file, setFile] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);



  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");




  
  const renderFileIcon = (fileUrl) => {
    if (!fileUrl) return null;

    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    let icon;

    switch (fileExtension) {
      case "pdf":
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
        break;
      default:
        icon = <PictureAsPdfIcon style={{ color: "#000", fontSize: 40 }} />;
    }

    return (
      <IconButton
        component="a"
        href={fileUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {icon}
      </IconButton>
    );
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    const url = `https://your-api-endpoint/files/delete/${fileDetails.id}`; // Replace with your API

    try {
      const response = await fetch(url, { method: "DELETE" });

      if (!response.ok) {
        throw new Error("Failed to delete file");
      }

      refreshContent();
      showSnackbar("Ficheiro removido com sucesso!");
      onClose();
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao eliminar ficheiro. Por favor tente outra vez");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const canEditDocuments =
    userRoles.includes("SU") || userRoles.includes("ADMIN");

  return (
    <Box m="20px">
      <Formik
        initialValues={{
          name: fileDetails.Name || "",
          file: fileDetails.FileURL || "",
          publicationDate: fileDetails.Publication_Date || "",
        }}
        validationSchema={checkoutSchema}
        enableReinitialize
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
        }) => (
          <>
            <form>
              <Box
                display="flex"
                gap="20px"
                flexDirection="column"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Nome do Ficheiro"
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  InputProps={{
                    readOnly: true,
                  }}
                />

                {fileDetails && renderFileIcon(fileDetails.FileURL)}

                {isEditable && (
                  <MuiFileInput
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachFileIcon />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Escolha um documento"
                    value={file}
                    sx={{ mt: 2 }}
                  />
                )}
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Data de publicação"
                  onBlur={handleBlur}
                  value={values.publicationDate}
                  name="publicationDate"
                  error={!!touched.publicationDate && !!errors.publicationDate}
                  helperText={touched.publicationDate && errors.publicationDate}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>

              {/* Action buttons */}
              {canEditDocuments && (
                <Box display="flex" justifyContent="space-between" mt="20px">
                  {!isEditable && (
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => setOpenDeleteDialog(true)}
                      disabled={isDeleting}
                      startIcon={
                        isDeleting ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : null
                      }
                    >
                      {isDeleting ? "A eliminar..." : "Eliminar Ficheiro"}
                    </Button>
                  )}

                  {/* {isEditable && (
                    <Button
                      color="error"
                      sx={{
                        "&.Mui-disabled": {
                          backgroundColor: "red",
                          color: "#fff",
                          opacity: 0.7,
                        },
                      }}
                      onClick={() => setOpenCancelDialog(true)}
                    >
                      Cancelar Alterações
                    </Button>
                  )}

                  <Button
                    onClick={() => {
                      if (isEditable) {
                        setOpenSaveDialog(true);
                      } else {
                        setIsEditable(true);
                      }
                    }}
                    sx={{
                      backgroundColor: colors.customTheme.primary,
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: colors.customTheme.darkPrimary,
                      },
                      "&.Mui-disabled": {
                        backgroundColor: colors.customTheme.primary,
                        color: "#fff",
                        opacity: 0.7,
                      },
                    }}
                    variant="contained"
                    disabled={isSubmitting}
                    startIcon={
                      isSubmitting ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : null
                    }
                  >
                    {isSubmitting
                      ? "A guardar..."
                      : isEditable
                      ? "Guardar Alterações"
                      : "Editar"}
                  </Button> */}
                </Box>
              )}
            </form>

            {/* Save Confirmation Dialog */}
            {/* <Dialog
              open={openSaveDialog}
              onClose={() => setOpenSaveDialog(false)}
            >
              <DialogTitle>Confirmar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja guardar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenSaveDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={handleFormSubmit}
                  color="primary"
                  disabled={isSubmitting}
                >
                  Sim, Guardar
                </Button>
              </DialogActions>
            </Dialog> */}

            {/* Delete Confirmation Dialog */}
            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
            >
              <DialogTitle>Eliminar Ficheiro</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja eliminar este ficheiro? Esta ação não
                  pode ser desfeita.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={() => onDeleteDocument(fileDetails.id)} color="error">
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>

            {/* Cancel Changes Confirmation Dialog */}
            {/* <Dialog
              open={openCancelDialog}
              onClose={() => setOpenCancelDialog(false)}
            >
              <DialogTitle>Cancelar Alterações</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem a certeza que deseja cancelar as alterações feitas?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenCancelDialog(false)}
                  color="primary"
                >
                  Não
                </Button>
                <Button
                  onClick={() => {
                    resetForm(); // Reseta o formulário
                    setIsEditable(false);
                    setOpenCancelDialog(false);
                  }}
                  color="error"
                >
                  Sim, Cancelar
                </Button>
              </DialogActions>
            </Dialog> */}
          </>
        )}
      </Formik>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  name: yup.string().required("required"),
});

export default FileDetails;
