import React, { useState } from "react";
import { Box, Button, TextField, Typography, Alert, Snackbar } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InputAdornment from "@mui/material/InputAdornment";
import { MuiFileInput } from "mui-file-input";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";


import NotificationToggle from "../../global/ShowNotificationField";

const AddCourseForm = ({ onClose, refreshCourses, showSnackbar }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [coverImage, setCoverImage] = useState(null); // Image state
  const [previewUrl, setPreviewUrl] = useState(""); // Preview URL for the image
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState(null);


  const [notificationData, setNotificationData] = useState(null);




  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");




  // Handle file change and validate the file type
  const handleImageChange = (newFile) => {
    // Verifica se o arquivo foi selecionado
    if (newFile) {
      // Verifica se o tipo do arquivo é de imagem
      if (!newFile.type.startsWith("image/")) {
        setSnackbarMessage("Deve apenas selecionar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setCoverImage(null);
        setPreviewUrl("");
        return;
      }
  
      // Verifica o tamanho do arquivo (limite de 20 MB)
      const maxSizeInMB = 20;
      if (newFile.size > maxSizeInMB * 1024 * 1024) {
        setSnackbarMessage("O tamanho do ficheiro não pode exceder 20MB.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setCoverImage(null);
        setPreviewUrl("");
        return;
      }
  
      // Define o arquivo e cria uma pré-visualização
      setCoverImage(newFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(newFile);
    } else {
      setCoverImage(null);
      setPreviewUrl("");
    }
  };
  
  
  const handleFormSubmit = async (values, setSubmitting) => {
    
    if (!coverImage) {
      setSnackbarMessage("Por favor, adicione uma imagem antes de enviar.");
      setSnackbarSeverity("warning");
      setSnackbarOpen(true);
      setSubmitting(false); // Evita o envio do formulário
      return;
    }

    
    
    const formData = new FormData();
    formData.append("name", values.name); // Name of the course
    formData.append("photo", coverImage); // Cover image file

    const url = "https://faculdades-3cket-aefml.oa.r.appspot.com/api/notes-bank/courses"; // Replace with your API

    try {
      const response = await fetch(url, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        setSnackbarMessage("Deve apenas selecinar ficheiros de imagem, como .png, .jpg, .jpeg, .webp ou avif");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);     
        throw new Error("Network response was not ok");
      }
      const responseData = await response.text();
      refreshCourses(); // Refresh the list of courses after adding
      showSnackbar("Curso adicionado com sucesso!");

      if (notificationData && notificationData.title && notificationData.body) {
        await sendNotification(notificationData);
      }

      onClose(); // Close the form after submission
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Erro ao adicionar um curso. Por favor tente de novo");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);     
    }

    setSubmitting(false); // Reset the submitting state
  };



  const sendNotification = async ({ title, body, screen }) => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("body", body);
      formData.append("screen", screen);

      const response = await fetch(
        "https://faculdades-3cket-aefml.oa.r.appspot.com/api/notifications/send",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        setSnackbarMessage(`Error sending notification: ${errorData.message}`);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage("Error sending notification.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };


  return (
    <Box m="20px">
      <Formik
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values, setSubmitting);
        }}
        initialValues={initialValues}
        validationSchema={courseSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" gap="20px" flexDirection="column">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nome do Curso"
                placeholder="Insira o nome do curso"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                name="name"
                error={!!touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              <MuiFileInput
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachFileIcon sx={{ color: "#000" }} /> {/* Icon */}
                    </InputAdornment>
                  ),
                }}
                placeholder="Carregar Imagem de Capa"
                value={coverImage}
                onChange={handleImageChange}
                accept="image/*" // Accept only image files
              />

              {/* Preview Image */}
              {previewUrl && (
                <Box
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  sx={{
                    width: "80%", // Limit the width to 80% of the container
                    margin: "0 auto",
                  }}
                >
                  <img
                    src={previewUrl}
                    alt="Preview"
                    style={{
                      maxWidth: "100%", // Fit within the container
                      maxHeight: "300px",
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              )}
            </Box>


            <NotificationToggle
              defaultTitle="Novo curso na tua sebenta"
              defaultBody={`Acede já às informações do novo curso`}
              screen="/notes-bank"
              onSend={(data) => setNotificationData(data)}
            />


            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                sx={{
                  mb: "1rem",
                  fontWeight: 600,
                  backgroundColor: colors.customTheme.primary,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: colors.customTheme.darkPrimary,
                  },
                  "&.Mui-disabled": {
                    backgroundColor: colors.customTheme.primary,
                    color: "#fff",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? "A guardar curso..." : "Criar Curso"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>



      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)', // Cor de fundo
            color: '#000', // Cor do texto
            borderRadius: '4px', // Bordas arredondadas
          },
        }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%', textAlign: 'center' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
};

// Validation schema
const courseSchema = yup.object().shape({
  name: yup.string().required("Nome do curso é obrigatório"),
});

// Initial form values
const initialValues = {
  name: "",
  coverImage: null, // Initialize the cover image as null
};

export default AddCourseForm;
