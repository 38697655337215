import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  TextField,
  Paper,
  Typography,
  useMediaQuery, 
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete'; 

import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";

const TableWithPagination = ({
  columns,
  rows,
  onRowClick,
  onButtonClick,
  canAddElements,
  buttonTitle,
  onDeleteClick,
  deleteButtonTitle,
  canDeleteElements,

}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" }); // Sorting state
  const isSmallScreen = useMediaQuery("(max-width:750px)");






  // Sorting handler
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (!sortConfig.key) return 0; // No sorting if no column is selected
    const aValue = a[sortConfig.key]?.toString().toLowerCase();
    const bValue = b[sortConfig.key]?.toString().toLowerCase();

    if (aValue < bValue) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });



  // Pagination handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page whenever rows per page changes
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setPage(0); // Reset to the first page whenever search term changes
  };

  // Filter rows based on the search term
  const filteredRows = rows.filter((row) =>
    columns.some((col) =>
      row[col.field] &&
      row[col.field].toString().toLowerCase().includes(searchTerm)
    )
  );

  return (
    <Box width="100%" paddingBottom="6rem">

<Box
  sx={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "1rem",
  }}
>
  {/* Campo de Pesquisa */}
  <TextField
    label="Procurar"
    variant="outlined"
    value={searchTerm}
    onChange={handleSearchChange}
    sx={{ width: "250px", zIndex: 0 }}
  />

  <Box sx={{ display: "flex", gap: "1rem" }}> {/* Container para alinhar os botões */}
    {canDeleteElements && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: 'red', // Vermelho para o botão de eliminar
          color: colors.customTheme.textColor,
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          padding: "8px",
          "&:hover": {
            backgroundColor: 'darkRed' // Cor de hover personalizada
          }
        }}
        onClick={onDeleteClick}
      >
        <DeleteIcon /> {/* Ícone de lixo */}
        {!isSmallScreen && `Eliminar ${deleteButtonTitle}`}
      </Button>
    )}

    {canAddElements && (
      <Button
        variant="contained"
        sx={{
          backgroundColor: colors.customTheme.primary,
          color: colors.customTheme.textColor,
          fontWeight: 600,
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          padding: "8px",
          "&:hover": {
            backgroundColor: colors.customTheme.darkPrimary
          }
        }}
        onClick={onButtonClick}
      >
        <AddIcon />
        {!isSmallScreen && `Adicionar ${buttonTitle}`}
      </Button>
    )}
  </Box>
</Box>


      {/* Tabela */}
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: colors.customTheme.primary }}>
                {columns.map((col, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    style={{
                      color: colors.customTheme.textColor,
                      fontWeight: 600,
                      verticalAlign: "top",
                      width: `${100 / columns.length}%`,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                    className={`column-${col.field}`}
                  >
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                minHeight: "400px",
                position: "relative"
              }}
            >
              {filteredRows.length === 0 ? (
                <TableRow sx={{ height: "300px" }}>
                  <TableCell
                    colSpan={columns.length}
                    style={{
                      textAlign: "center",
                      padding: "2rem 1rem",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)"
                    }}
                  >
                    <Typography variant="p" color="textSecondary">
                      Sem resultados
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      hover
                      style={{
                        backgroundColor:
                          rowIndex % 2 === 0 ? "#f3f3f3" : "#e9e9e9",
                        cursor: "pointer"
                      }}
                      onClick={() => onRowClick(row)}
                    >
                      {columns.map((col, colIndex) => (
                        <TableCell
                          key={colIndex}
                          align="center"
                          style={{
                            color: "#000",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "150px",
                            height: "40px",
                            display:
                              col.hideOnMobile && isSmallScreen
                                ? "none"
                                : "table-cell"
                          }}
                          className={`column-${col.field}`}
                        >
                          {col.isImage ? (
                            <img
                              src={row[col.field]}
                              alt={row.Name}
                              style={{
                                maxWidth: "80%",
                                maxHeight: "60px",
                                objectFit: "contain",
                                display: "block",
                                margin: "0 auto"
                              }}
                            />
                          ) : (
                            row[col.field]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Paginação */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count !== -1 ? count : `mais de ${to}`}`
          }
          ActionsComponent={(props) => (
            <Box
              display="flex"
              alignItems="center"
              gap="0.5rem"
              justifyContent="flex-start"
              marginRight="auto"
            >
              <IconButton
                onClick={(e) => props.onPageChange(e, 0)}
                disabled={props.page === 0}
              >
                <FirstPageRoundedIcon />
              </IconButton>
              <IconButton
                onClick={(e) => props.onPageChange(e, props.page - 1)}
                disabled={props.page === 0}
              >
                <ChevronLeftRoundedIcon />
              </IconButton>
              <IconButton
                onClick={(e) => props.onPageChange(e, props.page + 1)}
                disabled={
                  props.page >= Math.ceil(props.count / props.rowsPerPage) - 1
                }
              >
                <ChevronRightRoundedIcon />
              </IconButton>
              <IconButton
                onClick={(e) =>
                  props.onPageChange(
                    e,
                    Math.max(
                      0,
                      Math.ceil(props.count / props.rowsPerPage) - 1
                    )
                  )
                }
                disabled={
                  props.page >= Math.ceil(props.count / props.rowsPerPage) - 1
                }
              >
                <LastPageRoundedIcon />
              </IconButton>
            </Box>
          )}
        />
      </Paper>

      <style>
        {`
          /* Hide columns that have hideOnMobile set to true on mobile */
          @media (max-width: 750px) {
            ${columns
            .filter((col) => col.hideOnMobile)
            .map((col) => `.column-${col.field} { display: none !important; }`)
            .join("\n")}
          }

          /* Always hide columns that have hideAlways set to true */
          ${columns
            .filter((col) => col.hideAlways)
            .map((col) => `.column-${col.field} { display: none !important; }`)
            .join("\n")}
          
          /* For narrow screens, increase padding and adjust text alignment */
          @media (max-width: 750px) {
            td, th {
              padding: 0.5rem;
              text-align: left;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TableWithPagination;
